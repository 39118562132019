import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import tableStore from "@/store/modules/table";
import PaginationComponent from '../paginationcomponent/PaginationComponent.vue';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import APP_UTILITIES from "@/utilities/commonFunctions";
import API_CONST from '@/constants/ApiConst';
import { ProgramListDTO } from '@/services/program/adminService';

@Component({
  components: {
    'pagination': PaginationComponent
  }
})
export default class TableComponent extends Vue {
  @Prop()
  headers!: { name: string, value: string, order: number }[];

  @Prop()
  content!: [];

  @Prop()
  total_items!: 0;

  @Prop()
  isShow!: false

  @Prop()
  keys!: [];
  private objScreenText: ScreenText = new ScreenText();
  private deleteCell: any = '';

  @Prop()
  action!: { action: string };

  @Prop()
  sort_list!: any[];

  @Prop()
  componentName!: any[string];

  @Prop()
  tabSelected!: string;

  @Prop()
  isSearching!: boolean;

  @Prop()
  showResendModal!: boolean

  @Prop({ default: '' })
  accountName!: string;

  @Prop()
  initialPage!: number;
  currentID: number = 0;
  order: number = 1;
  sortField: string = '';
  getPage: number = 1;
  show: boolean = false;
  showDisableConfirmation: boolean = false;
  resetConfirmationPopup: boolean = false;
  upperVisible: boolean = false;
  id: number | null = null;
  lastdrop: boolean = false;
  getGlobalState = getModule(GlobalModule, store)
  updatedContent: any = {};
  manageListStatus: any[] = APP_CONST.STATUS_MAPPINGS;
  hovering: any = '';
  public hoverIndex: number = 100;
  public tooltipKeyId: number = -1
  account_admin_count = API_CONST.ACCOUNT_ADMIN_LIST_COUNT;
  public inviteCell: any = '';
  public isMobileView: boolean = false;
  public isDevice: boolean = false;
  public isDisableTrue: boolean = false;
  public scheduleDate: string = '';
  readonly STYLE = APP_CONST.STYLE;

  @Watch('headers', { deep: true, immediate: true })
  setSorting(header: [{ name: string, value: string, order: number, }]) {
    let el: { name: string, value: string, order: number };
    for (el of header) {
      if (el.order == 1) {
        this.order = el.order;
        this.sortField = el.value;
        break;
      }
    }
  }

  @Watch('content', { deep: true })
  checkContentUpdate(val: any) {
    if (this.componentName == 'manage-users') {
      this.prepareTableContent(val)
    }
  }

  @Watch('tabSelected', { deep: true, immediate: true })
  changeValue() {
    this.getPage = 1;
  }

  public currentRoleId: number = 0;

  beforeMount() {
    var userRoles: any = APP_UTILITIES.getCookie("user_role");
    var userData = JSON.parse(userRoles);
    this.currentRoleId = userData[0].roleId;
    this.isMobileView = APP_UTILITIES.isMobile();
    this.isDevice = APP_UTILITIES.mobileAndTabletCheck();
    window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));
  }

  isMobile() {
    this.isMobileView = APP_UTILITIES.isMobile();
  }

  prepareTableContent(val: any) {
    if (typeof (val) !== 'string') {
      let newObjArray: any = val.length > 0 && [...val];
      newObjArray.length > 0 && newObjArray.map((obj: any) => {
        obj['role']['userId'] = obj['userId'] || obj['role']['userId'];
        obj['role']['phoneNumber'] = obj['phoneNumber'];
        obj['role']['personalInvitationNote'] = obj['personalInvitationNote'];
        obj['action'] = 'Edit User'
        delete obj.userId;
      })
      this.updatedContent = newObjArray;
    }
    else {
      this.updatedContent = val;
    }
  }

  get isDeleteUserStatusActive() {
    return this.getGlobalState.getDeleteUserStatus
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  callHover(keyValue: string, index: number, keyId: number) {
    this.hovering = keyValue;
    this.hoverIndex = index;
    this.tooltipKeyId = keyId;
  }

  callHoverOut() {
    this.hovering = ''
  }


  getDataForPage(page: number) {
    this.id = null;
    this.getPage = page;
    this.$emit('selectedPage', page);
    this.$emit(APP_CONST.PAYLOAD, { page, sortField: this.sortField, sortDir: this.order, type: 'pagination' });
  }

  sortByApi(header: any) {
    this.id = null;
    if (!header.hasOwnProperty('order') && !isNaN(header.order)) {
      return
    }
    this.headers.forEach((item: { name: string, value: string, order: number }) => {
      if (item.value == header.value) {
        item.order = header.order === 1 ? 2 : 1;
        this.sortField = header.value;
        this.order = item.order;
      } else {
        item.order = 0;
      }
    })
    this.$emit(APP_CONST.PAYLOAD, { page: this.getPage, sortField: header.value, sortDir: this.order });
  }


  getAccountdminPic(type: string, base64: string) {
    let profileImageSrc = '';
    if (type !== '' || base64 !== '') {
      profileImageSrc = 'data:' + type + ';' + 'base64,' + base64;
    }
    return profileImageSrc;
  }

  addNewProgram(accountData: any) {
    this.$store.dispatch('globalModule/assignAccountId', accountData.accountId);
    APP_UTILITIES.setCookie('accountId', JSON.stringify(accountData.accountId), 1);
    this.$router.push('/accounts/createprogram');
  }

  getProgramId(programId: number, status: string) {
    this.id = null;
    this.$emit(APP_CONST.PROGRAMID, { programId, status: status });
    this.showDisableConfirmation = false;
  }

  showConfirmation(event: any, programId: number, index: number) {
    let y: any = event.clientY;
    this.showDisableConfirmation = true;
    this.lastdrop = false;
    this.upperVisible = false;
    this.currentID = programId;
    if (this.content.length - 1 == index) {
      this.upperVisible = true;
    }
    if (this.content.length > 1) {
      if (y >= 150 && y <= 179) {
        window.scrollBy(0, -150);
      }
      else if (y >= 180 && y <= 300) {
        window.scrollBy(0, -100);
      } else if (y > 300 && y <= 350) {
        window.scrollBy(0, -50);
      } else if (y > 350 && y <= 400) {
        window.scrollBy(0, 50);
      } else if (y > 400 && y <= 450) {
        window.scrollBy(0, 100);
      } else if (y > 450) {
        window.scrollBy(0, 150);
      }
    }

  }

  showResetConfirmation(event: any, programId: number, index: number) {
    let y: any = event.clientY;
    this.resetConfirmationPopup = true;
    this.lastdrop = false;
    this.upperVisible = false;
    this.currentID = programId;
    if (this.content.length - 1 == index) {
      this.upperVisible = true;
    }
    if (y >= 150 && y <= 179) {
      window.scrollBy(0, -150);
    }
    else if (y >= 180 && y <= 300) {
      window.scrollBy(0, -100);
    } else if (y > 300 && y <= 350) {
      window.scrollBy(0, -50);
    } else if (y > 350 && y <= 400) {
      window.scrollBy(0, 50);
    } else if (y > 400 && y <= 450) {
      window.scrollBy(0, 100);
    } else if (y > 450) {
      window.scrollBy(0, 150);
    }
  }

  resetProgram(programData: ProgramListDTO) {
    this.resetConfirmationPopup = false;
    this.$emit('resetProgram', programData);
  }

  showHideDrop(index: number, data: any) {
    this.disableResetPassword(data);
    if (index === this.id) {
      this.id = null;
    } else {
      this.id = index;
    }
    if (this.content.length > 3) {
      if (index === (this.content.length - 1) || (this.isMobileView && (index === (this.content.length - 2)))) {
        this.lastdrop = true;
      } else {
        this.lastdrop = false;
      }
    } else {
      this.lastdrop = false;
    }
    this.getGlobalState.updateDeleteUserStatus(false)
    this.showResendModal = false;
  }

  navigateToEditProgram(objData: any) {
    document.cookie = "editProgramId=" + objData.programId;
    this.$router.push('/accounts/editprogram');
  }

  formatDate(date: string) {
    return APP_UTILITIES.formatShortDate(date);
  }

  editUser(user: any, index: number) {
    this.$emit('userDetailsToEdit', { userId: user.role.userId });
  }

  resetPassword(user: any, index: number) {
    this.$emit('showResetPasswordPopup', { userId: user.role.userId, name: user.firstName ? (`${user.firstName} ${user.lastName}.`) : '' });
  }

  readUserDetails(user: any, index: number) {
    user['index'] = index
    this.$emit('readUser', user);
  }

  showProgramAdminDashboard(event: any) {
    let programIdId = event.target.id.split('-')[1];
    APP_UTILITIES.setCookie('programId', programIdId, 1)
  }

  editAccount(accountData: any): void {
    APP_UTILITIES.setCookie('accountId', JSON.stringify(accountData.accountId), 1);
    const query = { accountId: accountData.accountId, accountName: accountData.accountName }
    tableStore.QueryForProgram(query);
    this.$store.dispatch('globalModule/assignAccountId', accountData.accountId)
    this.$router.push('/accounts/edit');
  }

  navigateToProgram(accountData: any): void {
    let accountId: string = accountData.accountId.toString();
    APP_UTILITIES.setCookie('accountId', accountId, 1);
    setTimeout(() => {
      const query = { accountId: accountData.accountId, accountName: accountData.accountName }
      tableStore.QueryForProgram(query);
      this.$store.dispatch('globalModule/assignAccountId', accountData.accountId)
      this.$router.push('/account/programs');
      this.id = null;
    }, 0)
  }

  hideDrop(event: any) {
    if (!event.target.classList.contains('kebab_menu')) {
      this.id = null;
    }
  }

  navigateToProgramAdmin(data: any) {
    if (data.status != 2) {
      let userRoles: any = APP_UTILITIES.getCookie("user_role");
      let accountId: any = APP_UTILITIES.getCookie("accountId");
      let program_id = data.programId;
      let program_name = data.programName;

      let userData = JSON.parse(userRoles);
      userData[0].roleId = APP_CONST.PROGRAM_ADMIN_ROLE_ID;
      userData[0].accountId = accountId;
      userData[0].programId = program_id;

      APP_UTILITIES.setCookie("super_user_drilldown", 'true', 1);
      APP_UTILITIES.setCookie("user_role", JSON.stringify(userData), 1)
      APP_UTILITIES.setCookie("progName", program_name, 1);
      APP_UTILITIES.setCookie("programId", program_id, 1);
      APP_UTILITIES.setCookie("accountName", this.accountName, APP_CONST.ONE);
      this.$router.push('/home');

      this.$emit('loadNav', {});
    }
  }
  getInitialLater(firstName: string, lastName: string) {
    return firstName.trim().charAt(0).toUpperCase() + "" + lastName.trim().charAt(0).toUpperCase()
  }
  getColorCode(email: string) {
    return APP_UTILITIES.getColorCode(email)
  }

  checkLength(adminData: []) {
    if (adminData.length > 3) {
      return adminData.slice(0, 3);
    }
    return adminData
  }

  openResentConfirm(rowIndex: any, keyId: any, status: string) {
    if (status && status == 'InviteExpired' || status == 'InvitePending') {
      this.inviteCell = `${rowIndex}:${keyId}`;
      this.showResendModal = true;
      this.getGlobalState.updateDeleteUserStatus(false);
      if (this.content.length > 3) {
        if (rowIndex === (this.content.length - 1) || (this.isMobileView && (rowIndex === (this.content.length - 2)))) {
          this.lastdrop = true;
        } else {
          this.lastdrop = false;
        }
      } else {
        this.lastdrop = false;
      }
    }
  }
  resendInvitation(user: any, index: number) {
    this.$emit('editBxUser', { email: user.email, roleId: user.role.id, index: index });
    this.inviteCell = '';
  }

  showRemoveUserPopup(rowIndex: any, keyId: any) {
    this.inviteCell = `${rowIndex}:${keyId}`;
    this.getGlobalState.updateDeleteUserStatus(true)
    this.showResendModal = false;
  }

  removeUser(user: any, index: number) {
    this.getGlobalState.updateDeleteUserStatus(false)
    this.$emit('removeUser', user);
  }

  cancelUser() {
    this.getGlobalState.updateDeleteUserStatus(false)
  }

  disableUserAction(data: any) {
    const { id, roleId } = APP_UTILITIES.coreids();
    return (data.role.userId == id || data.role.id < roleId);
  }

  disableEditAction(data: any) {
    const { roleId } = APP_UTILITIES.coreids();
    return (data.role.id < roleId);
  }

  updateAccountStatus(accountId: number, status: string) {
    this.id = null;
    this.$emit('updateAccountStatus', { accountId, status: status });
    this.showDisableConfirmation = false;
  }

  disableResetPassword(data: any) {
    const { id, roleId } = APP_UTILITIES.coreids();
    let today: string | Date = new Date();
    if (data && data.scheduleDate) {
      this.scheduleDate = data.scheduleDate;
      this.scheduleDate = this.scheduleDate.split(' ')[0];
    }
    if ((data && data.status && data.status.name == APP_CONST.INACTIVE) || (data && data.role && data.role.id == APP_CONST.NINE) || (data && data.role && data.role.userId == id) || (data && data.role && data.role.id < roleId) || (data && this.scheduleDate > APP_UTILITIES.formatShortDate(today.toDateString()) && !data.emailConfirmed)) {
      this.isDisableTrue = true;
    }
    else if (data && data.role && data.role.id > APP_CONST.FOUR) {
      this.isDisableTrue = (data && data.role.programId == 0) ? APP_CONST.TRUE : APP_CONST.FALSE;
    }
    else {
      this.isDisableTrue = false;
    }
  }


}